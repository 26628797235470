<template>
  <li ref="booking" class="tabsDashboard__tab js-tab-item" data-type="booking">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text"><b>Booking confirmations</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="checked-circle"/>
          <span
            v-if="booking.length !== 0"
            class="tabsDashboard__tab-icon-count js-booking-count"
          >{{booking.length}}</span>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__booking">
        <div v-if="!booking.length" class="tabsDashboard__empty js-booking-empty">no booking</div>
        <div
          v-for="item of booking"
          :key="item.id"
          class="tabsDashboard__booking-item js-booking-item"
          :class="{'confirmation' : item.confirmation}"
        >
          <div class="tabsDashboard__booking-route">
            <span class="text">{{item.from}}</span>
            <span class="icon"><SvgIcon name="double-long-arrows"/></span>
            <span class="text">{{item.to}}</span>
          </div>
          <div class="tabsDashboard__booking-button-wr">
            <div
              class="tabsDashboard__booking-button tabsDashboard__booking-button--confirm js-booking-confirm"
              @click="addConfirmation(item.id)"
            >
              <SvgIcon name="checked"/>
            </div>
            <a
              class="tabsDashboard__booking-button tabsDashboard__booking-button--remove js-booking-remove"
              @click="showModal(item.id)">
              <SvgIcon name="close"/>
            </a>
          </div>
          <div class="tabsDashboard__booking-date">{{item.startDate}} - {{item.endDate}}</div>
        </div>
      </div>
    </div>
    <QuestionPopup
      :name="'questionPopupBookingConfirmations'"
      :title="'The booking will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'

  export default {
    props: ['visible'],
    name: 'TabsDashboardBooking',
    computed: {
      booking () {
        return this.$store.state.booking.bookingConfirmation
      },
    },
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.booking)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.booking.getAttribute('data-type'))
      },
      addConfirmation (id) {
        this.$store.commit('addConfirmation', id)
      },
      showModal (id) {
        this.$modal.show('questionPopupBookingConfirmations')
        this.$store.commit('insertCurrentBooking', id)
      },
      remove () {
        this.$store.commit('removeItemBooking')
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
