<template>
  <li ref="personnel" class="tabsDashboard__tab js-tab-item" data-type="personnel">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">Personnel</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="pilot-hat"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="#">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__personnel">
        <div class="tabsDashboard__personnel-title">Team members</div>
        <PersonnelDashboardMembers :personnel="personnel"/>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import PersonnelDashboardMembers from '@/components/dashboard/PersonnelDashboardMembers/PersonnelDashboardMembers'

  export default {
    props: ['visible'],
    name: 'TabsDashboardPersonnel',
    computed: {
      personnel () {
        return this.$store.state.personnel.list
      },
    },
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.personnel)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.personnel.getAttribute('data-type'))
      },
    },
    components: {
      SvgIcon,
      PersonnelDashboardMembers,
    },
  }
</script>
