<template>
  <div class="tabsDashboard">
    <vue-scroll :ops="ops">
      <div class="tabsDashboard__header">
        <div class="tabsDashboard__title">Customize your dashboard</div>
        <div class="tabsDashboard__text">
          <span class="text text--desktop">You can put any desired tab on the dashboard. Just drag it from the left vertical menu</span>
          <span class="text text--mobile">You can put any desired tab on the dashboard. “ADD NEW TAB” at the bottom of the page</span>
        </div>
      </div>
      <ul class="tabsDashboard__content js-tab-list">
        <div style="display:none;">
        <TabsDashboardFlights :type="'provider'" :visible="sections.flights" @hideSection="hideSection"/>
        <TabsDashboardProfile :type="'provider'" :visible="sections.profile" @hideSection="hideSection"/>
        <TabsDashboardMessages :visible="sections.messages" @hideSection="hideSection"/>
        <TabsDashboardFlyRewards :type="'provider'" :visible="sections.flyRewards" @hideSection="hideSection"/>
        <TabsDashboardRequests :type="'provider'" :miniTab="true" :visible="sections.requests" @hideSection="hideSection"/>
        <TabsDashboardBooking :visible="sections.booking" @hideSection="hideSection"/>
        <TabsDashboardAuctions :type="'provider'" :visible="sections.auctions" @hideSection="hideSection"/>
        <TabsDashboardPayment :type="'provider'" :visible="sections.payment" @hideSection="hideSection"/>
        <TabsDashboardPersonnel :visible="sections.personnel" @hideSection="hideSection"/>
        <TabsDashboardFleet :type="'provider'" :visible="sections.fleet" @hideSection="hideSection"/>
        <!-- <TabsDashboardCharterUploads :type="'provider'" :visible="sections.charter" @hideSection="hideSection"/> -->
        <!-- <TabsDashboardFlightUploads :type="'provider'" :visible="sections.flight" @hideSection="hideSection"/> -->
        </div>
        <!-- <TabsDashboardAdd :type="'provider'" @showSection="showSection"/> -->
      </ul>
    </vue-scroll>
  </div>
</template>

<script>
  import TabsDashboardFlights from '@/components/dashboard/tabs/TabsDashboardFlights'
  import TabsDashboardProfile from '@/components/dashboard/tabs/TabsDashboardProfile'
  import TabsDashboardMessages from '@/components/dashboard/tabs/TabsDashboardMessages'
  import TabsDashboardFlyRewards from '@/components/dashboard/tabs/TabsDashboardFlyRewards'
  import TabsDashboardRequests from '@/components/dashboard/tabs/TabsDashboardRequests'
  import TabsDashboardBooking from '@/components/dashboard/tabs/TabsDashboardBooking'
  import TabsDashboardAuctions from '@/components/dashboard/tabs/TabsDashboardAuctions'
  import TabsDashboardPayment from '@/components/dashboard/tabs/TabsDashboardPayment'
  import TabsDashboardPersonnel from '@/components/dashboard/tabs/TabsDashboardPersonnel'
  import TabsDashboardFleet from '@/components/dashboard/tabs/TabsDashboardFleet'
  // import TabsDashboardCharterUploads from '@/components/dashboard/tabs/TabsDashboardCharterUploads'
  // import TabsDashboardFlightUploads from '@/components/dashboard/tabs/TabsDashboardFlightUploads'
  // import TabsDashboardAdd from '@/components/dashboard/tabs/TabsDashboardAdd'
  import '@/views/dashboard/TabsDashboard.styl'

  export default {
    name: 'DashboardProvider',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      sections: {
        flights: true,
        profile: true,
        messages: true,
        flyRewards: true,
        requests: true,
        booking: true,
        payment: true,
        auctions: true,
        personnel: true,
        fleet: true,
        charter: true,
        flight: true,
      },
    }),
    methods: {
      hideSection (value) {
        console.log(value)
        this.sections[`${value}`] = false
      },
      showSection (value) {
        this.sections[`${value}`] = true
      },
    },
    components: {
      TabsDashboardFlights,
      TabsDashboardProfile,
      TabsDashboardMessages,
      TabsDashboardFlyRewards,
      TabsDashboardRequests,
      TabsDashboardBooking,
      TabsDashboardAuctions,
      TabsDashboardPayment,
      TabsDashboardPersonnel,
      TabsDashboardFleet,
      // TabsDashboardCharterUploads,
      // TabsDashboardFlightUploads,
      // TabsDashboardAdd
    },
  }
</script>
