<template>
  <li ref="payment" class="tabsDashboard__tab js-tab-item" data-type="payment">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div v-if="type === 'flyer'" class="tabsDashboard__tab-text"><b>Payment</b> information</div>
        <div v-else class="tabsDashboard__tab-text"><b>Payment</b> and bank info</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="wallet"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/payment">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div v-if="type === 'flyer'" class="tabsDashboard__payment">
        <div v-if="!payment.length">no payment information</div>
        <div v-for="item of payment" :key="item.id" class="tabsDashboard__payment-method">
          <div class="tabsDashboard__payment-method-top">
            <div class="tabsDashboard__payment-method-preview">
              <div class="tabsDashboard__payment-method-preview-img">
                <img src="@/assets/img/dashboard/visa-2.png" alt=""/>
              </div>
              <div class="tabsDashboard__payment-method-preview-number">{{item.cardNumber}}</div>
            </div>
            <div class="tabsDashboard__payment-method-info">
              <div class="tabsDashboard__payment-method-info-item">
                <div class="tabsDashboard__payment-method-info-item-title">{{item.paymentType}}</div>
                <div class="tabsDashboard__payment-method-info-item-subtitle">{{item.cardType}}</div>
              </div>
              <div class="tabsDashboard__payment-method-info-item">
                <div class="tabsDashboard__payment-method-info-item-caption">Billing address:</div>
                <div class="tabsDashboard__payment-method-info-item-value">{{item.billingAddress}}</div>
              </div>
              <div class="tabsDashboard__payment-method-info-item">
                <div class="tabsDashboard__payment-method-info-item-caption">Card holder:</div>
                <div class="tabsDashboard__payment-method-info-item-value">{{item.cardHolder}}</div>
              </div>
              <div class="tabsDashboard__payment-method-info-item">
                <div class="tabsDashboard__payment-method-info-item-caption">Expiration date:</div>
                <div class="tabsDashboard__payment-method-info-item-value">{{item.expirationDate}}</div>
              </div>
            </div>
          </div>
          <div class="tabsDashboard__payment-method-bottom">
            <router-link class="tabsDashboard__payment-method-link" to="#">Update card data</router-link>
            <div
              class="tabsDashboard__payment-method-link tabsDashboard__payment-method-link--remove js-popup"
              @click="showModal(item.id)"
            >Remove card
            </div>
          </div>
        </div>
      </div>
      <div v-else class="tabsDashboard__payment">
        <router-link class="tabsDashboard__payment-button" to="provider-dashboard/payment">
          <span class="text">Add payment method</span>
        </router-link>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer"><a class="tabsDashboard__tab-link forMobile">See full</a></div>
    <QuestionPopup
      :name="'QuestionPopupPayment'"
      :title="'The payment will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'

  export default {
    props: ['type', 'visible'],
    name: 'TabsDashboardPayment',
    computed: {
      payment () {
        return this.$store.getters.getUpdatedPayment
      },
    },
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.payment)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.payment.getAttribute('data-type'))
      },
      showModal (item) {
        this.$modal.show('QuestionPopupPayment')
        this.$store.commit('insertPaymentToRemove', item)
      },
      remove () {
        this.$store.commit('removeItemPayment')
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
